// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import Link from '../../components/link';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
    _12: ImageType,
    _13: ImageType,
  },
};

class Blog_2015_10_23_Egypt_2 extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="4AII" title="Egypt 2" />
        <p>
          <Link to="http://ropewiki.com/Egypt_2">Egypt 2</Link> was my first
          Escalante canyon. The 270′ entrance rappel provided a great
          introduction to this spectacular area.
        </p>
        <p>
          After meeting at{' '}
          <Link to="http://excursionsofescalante.com/">
            Excursions of Escalante
          </Link>{' '}
          in the morning, we separated into groups. We then headed off to the
          Egypt bench to descend canyons.
        </p>
        <p>
          The most well known feature of Egypt 2 is its first rappel – 270′
          right off the road. A few years ago, it was common to rig the rope
          directly to your vehicle and do the rappel from there. This is now
          highly discouraged. Instead, there is a small arch you can use{' '}
          <GlossaryLink id="right-down-canyon">RDC</GlossaryLink>.
        </p>
        <Image caption="Entrance rappel" image={data._1} />
        <p>It was kind of muddy in there.</p>
        <Image image={data._2} />
        <Image image={data._3} />
        <Image image={data._4} />
        <Image image={data._5} />
        <p>
          Despite this, there were plenty of fun down climbs and skinny
          sections.
        </p>
        <Image image={data._6} />
        <Image image={data._7} />
        <Image image={data._8} />
        <p>
          Overall, the canyon was a blast and I met some amazing canyoneers that
          day.
        </p>
        <Image image={data._9} />
        <Image image={data._10} />
        <Image image={data._11} />
        <Image image={data._12} />
        <Image image={data._13} />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2015-10-23-egypt-2')(Blog_2015_10_23_Egypt_2);

export const query = graphql`
  query {
    _1: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _10: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _11: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _12: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _13: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _14: file(relativePath: { eq: "images/blog/2015-10-23-egypt-2/14.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
